"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _lodash = require("lodash");

var _jsAcl = _interopRequireDefault(require("js-acl"));

var _userPrivacyStatus = require("../../constants/user-privacy-status");

var _userRole = require("../user-role");

var ROLE_GUEST = 'guest';
var ROLE_USER = 'user';
var ROLE_MODERATOR = 'moderator';
var ROLE_ADMIN = 'admin';
var ROLE_OWNER = 'owner';
var RESOURCE_FORUM = 'forum';
var RESOURCE_CATEGORY = 'category';
var RESOURCE_POST = 'post';
var RESOURCE_COMMENT = 'comment';
var RESOURCE_MEMBER = 'member';
var PERMISSIONS_ALL = 'all';
/*
 * PermissionsChecker provides 2 types of checks:
 * - can: this checks if a role can do an action on resource
 * - canSee: this checks if a role can see an action on resource (know that there is an ability to do such action)
 *
 * They are mostly identical, but for some resources action they differ. For example,
 * everyone can see like on post, but only logged in, non blocked, non private can like.
 *
 * If a role can do an action on resource, this means he can also see.*/

function permissionsChecker(_temp, user, resolve) {
  var _ref = _temp === void 0 ? {} : _temp,
      _ref$isOldForum = _ref.isOldForum,
      isOldForum = _ref$isOldForum === void 0 ? false : _ref$isOldForum,
      _ref$isMemberAppInsta = _ref.isMemberAppInstalled,
      isMemberAppInstalled = _ref$isMemberAppInsta === void 0 ? false : _ref$isMemberAppInsta,
      _ref$isMemberAreaInst = _ref.isMemberAreaInstalled,
      isMemberAreaInstalled = _ref$isMemberAreaInst === void 0 ? false : _ref$isMemberAreaInst;

  var hasMembersFunctions = isOldForum || isMemberAppInstalled || isMemberAreaInstalled;

  var setupAcl = function setupAcl(acl, isSee) {
    // Roles
    acl.addRole('guest'); // acl.addRole('user', 'guest');

    acl.addRole('user');
    acl.addRole('moderator', 'user');
    acl.addRole('admin', 'user');
    acl.addRole('owner', 'admin'); // Resources

    acl.addResource('forum');
    acl.addResource('category');
    acl.addResource('post', 'category');
    acl.addResource('comment', 'post');
    acl.addResource('member'); // Guest

    acl.allow('guest', 'category', 'read', and(not(resourceIsMembersOnly), resourceIsAccesible));
    acl.allow('guest', 'category', 'share');

    if (isSee) {
      if (hasMembersFunctions) {
        acl.allow('guest', 'category', 'subscribe', not(userIsBlocked));
      }

      acl.allow('guest', 'post', ['like', 'vote'], and(not(resourceIsMembersOnly), resourceIsAccesible));
    }

    acl.allow('guest', 'member', 'edit', userIsResource); // User

    acl.allow('user', 'category', 'share');
    acl.allow('user', 'category', 'read', and(or(resourceIsAccesible, userHasPermission('read'), userHasPermission('write')), or(and(not(resourceIsMembersOnly), resourceIsAccesible), and(resourceIsMembersOnly, not(userIsPrivate)), and(resourceIsAccesible, not(userIsPrivate)))));

    if (hasMembersFunctions) {
      acl.allow('user', 'category', 'subscribe', isSee ? or(resourceIsAccesible, userHasPermission('read'), userHasPermission('write')) : and(or(resourceIsAccesible, userHasPermission('read'), userHasPermission('write')), not(userIsPrivate)));
    }

    acl.allow('user', 'category', 'create-post', isSee ? or(and(not(resourceIsWriteProtected), resourceIsAccesible), userHasPermission('write')) : or(and(not(resourceIsWriteProtected), resourceIsAccesible, not(userIsPrivate)), userHasPermission('write')));
    acl.allow('user', 'post', ['like', 'vote'], isSee ? or(resourceIsAccesible, userHasPermission('read'), userHasPermission('write')) : and(or(resourceIsAccesible, userHasPermission('read'), userHasPermission('write')), not(userIsPrivate)));
    acl.allow('user', 'post', 'edit', isSee ? userIsResourceOwner : and(userIsResourceOwner, not(userIsPrivate)));
    acl.allow('user', 'post', 'delete', userIsResourceOwner);
    acl.allow('user', 'post', 'create-comment', and(not(resourceHasCommentsDisabled), or(resourceIsAccesible, userHasPermission('read'), userHasPermission('write')), isSee ? function () {
      return true;
    } : not(userIsPrivate)));
    acl.allow('user', 'post', ['add-top-comment', 'remove-top-comment', 'add-best-answer-comment', 'remove-best-answer-comment'], userIsResourceOwner);

    if (hasMembersFunctions) {
      acl.allow('user', 'post', 'report', isSee ? and(not(userIsResourceOwner), not(resourceOwnerHasRoles('owner'))) : and(not(userIsResourceOwner), not(resourceOwnerHasRoles('owner')), not(userIsPrivate)));
      acl.allow('user', 'post', 'subscribe', isSee ? or(userHasPermission('read'), userHasPermission('write')) : and(or(userHasPermission('read'), userHasPermission('write')), not(userIsPrivate)));
    } else {
      acl.deny('user', 'post', 'subscribe');
    }

    if (hasMembersFunctions) {
      acl.allow('user', 'comment', 'report', isSee ? and(not(userIsResourceOwner), not(resourceOwnerHasRoles('owner'))) : and(not(userIsResourceOwner), not(resourceOwnerHasRoles('owner')), not(userIsPrivate)));
    }

    acl.allow('user', 'comment', ['like', 'vote'], isSee ? or(userHasPermission('read'), userHasPermission('write')) : and(or(userHasPermission('read'), userHasPermission('write')), not(userIsPrivate)));
    acl.allow('user', 'comment', 'edit', isSee ? userIsResourceOwner : and(userIsResourceOwner, not(userIsPrivate)));
    acl.allow('user', 'comment', 'delete', userIsResourceOwner);

    if (hasMembersFunctions) {
      acl.allow('user', 'member', 'subscribe', and(not(userIsResource), not(userIsPrivate)));
      acl.allow('user', 'member', 'list', not(userIsPrivate));
      acl.allow('user', 'member', 'report', and(and(not(userIsResource), not(resourceHasRoles('owner'))), not(userIsPrivate)));
    }

    acl.allow('user', 'member', 'edit', userIsResource);
    acl.allow('user', 'member', 'delete', and(userIsResource, not(resourceHasRoles('owner')))); // Moderator

    acl.allow('moderator', 'category', 'read');
    acl.allow('moderator', 'category', ['create-post', 'edit', 'subscribe'], userHasPermission('moderate'));
    acl.allow('moderator', 'post', ['pin', 'toggle-comments', 'move', 'create-comment', 'add-top-comment', 'remove-top-comment', 'add-best-answer-comment', 'remove-best-answer-comment', 'like'], userHasPermission('moderate'));
    acl.allow('moderator', 'post', 'delete', and(userHasPermission('moderate'), not(resourceOwnerHasRoles('admin', 'owner'))));
    acl.allow('moderator', 'post', 'create-comment', and(not(resourceHasCommentsDisabled), userHasPermission('moderate')));
    acl.allow('moderator', 'comment', ['like', 'vote'], userHasPermission('moderate'));
    acl.deny('moderator', 'post', 'edit', not(userIsResourceOwner));
    acl.deny('moderator', 'post', 'report', userHasPermission('moderate'));
    acl.deny('moderator', 'comment', 'report', userHasPermission('moderate')); // Admin

    acl.allow('admin', 'forum', 'reorder-categories');
    acl.allow('admin', 'category', ['read', 'subscribe', 'create', 'create-post', 'edit', 'delete']);
    acl.allow('admin', 'post', ['pin', 'toggle-comments', 'move', 'create-comment', 'delete', 'add-top-comment', 'remove-top-comment', 'add-best-answer-comment', 'remove-best-answer-comment', 'like', 'vote']);
    acl.allow('admin', 'post', 'create-comment', not(resourceHasCommentsDisabled));
    acl.allow('admin', 'comment', ['delete', 'like', 'vote']);
    acl.allow('admin', 'member', 'block', and(not(resourceHasRoles('admin', 'owner')), not(userIsResource)));
    acl.allow('admin', 'member', 'delete', not(resourceHasRoles('admin', 'owner')));
    acl.allow('admin', 'member', 'promote-moderator', and(not(resourceIsBlocked), not(resourceHasRoles('admin', 'owner')), not(userIsResource)));
    acl.deny('admin', 'post', 'edit', not(userIsResourceOwner));
    acl.deny('admin', 'post', 'report');
    acl.deny('admin', 'comment', 'report'); // Owner

    acl.allow('owner', 'forum', 'edit');
    acl.allow('owner', 'member', 'block', not(resourceHasRoles('owner')));
    acl.allow('owner', 'member', 'delete', not(resourceHasRoles('owner')));
    acl.allow('owner', 'member', 'promote', and(not(resourceIsBlocked), not(resourceHasRoles('owner'))));
    acl.deny('owner', 'member', 'report');
    return acl;
  };

  var acl = setupAcl(new _jsAcl["default"]());
  var aclSee = setupAcl(new _jsAcl["default"](), hasMembersFunctions);
  var wrapped = {
    ROLE_GUEST: ROLE_GUEST,
    ROLE_USER: ROLE_USER,
    ROLE_MODERATOR: ROLE_MODERATOR,
    ROLE_ADMIN: ROLE_ADMIN,
    ROLE_OWNER: ROLE_OWNER,
    RESOURCE_FORUM: RESOURCE_FORUM,
    RESOURCE_CATEGORY: RESOURCE_CATEGORY,
    RESOURCE_POST: RESOURCE_POST,
    RESOURCE_COMMENT: RESOURCE_COMMENT,
    RESOURCE_MEMBER: RESOURCE_MEMBER,
    PERMISSIONS_ALL: PERMISSIONS_ALL,
    resourcify: resourcify,
    setUserIdentity: function setUserIdentity(user) {
      var identity = (0, _extends2["default"])({}, user);
      var role = (0, _userRole.getUserRole)(identity);

      if ((0, _lodash.isEmpty)(identity)) {
        role = 'guest';
      }

      if ((0, _lodash.isEmpty)(role)) {
        role = identity.isOwner ? 'owner' : 'user';
      }

      if ((0, _lodash.get)(identity, 'isBlocked')) {
        role = 'guest';
      } // when member area not installed, we don't care about user being private.


      if (!isMemberAreaInstalled) {
        identity.privacyStatus = _userPrivacyStatus.USER_PRIVACY_STATUS_PUBLIC;
      }

      acl.setUserIdentity(rolify(identity, role));
      aclSee.setUserIdentity(rolify(identity, role));
    },
    can: function can(resource, permission) {
      return acl.can(resource, permission);
    },
    canSee: function canSee(resource, permission) {
      return aclSee.can(resource, permission);
    }
  };
  wrapped.setUserIdentity(user);
  return wrapped;

  function not(fn) {
    var _this = this;

    return function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return !fn.apply(_this, args);
    };
  }

  function and() {
    var _this2 = this;

    for (var _len2 = arguments.length, fns = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      fns[_key2] = arguments[_key2];
    }

    return function () {
      for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        args[_key3] = arguments[_key3];
      }

      return (0, _lodash.every)(fns, function (fn) {
        return fn.apply(_this2, args);
      });
    };
  }

  function or() {
    var _this3 = this;

    for (var _len4 = arguments.length, fns = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
      fns[_key4] = arguments[_key4];
    }

    return function () {
      for (var _len5 = arguments.length, args = new Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
        args[_key5] = arguments[_key5];
      }

      return (0, _lodash.some)(fns, function (fn) {
        return fn.apply(_this3, args);
      });
    };
  }

  function userHasPermission(permission) {
    return function (user, resource) {
      var permissions = (0, _lodash.get)(user, "permissions." + permission, []);
      return (0, _lodash.includes)(permissions, PERMISSIONS_ALL) || (0, _lodash.includes)(permissions, resolve(resource)._id);
    };
  }

  function userIsResource(user, resource) {
    return user._id === resource._id || user.siteMemberId === resource.siteMemberId && user.siteMemberId !== undefined;
  }

  function userIsResourceOwner(user, resource) {
    return user._id && resource.ownerId && user._id === resource.ownerId || user.siteMemberId === resource.ownerSId && user.siteMemberId !== undefined || user.userId === resource.ownerSId && user.userId !== undefined;
  }

  function userIsPrivate(user) {
    return user.privacyStatus === _userPrivacyStatus.USER_PRIVACY_STATUS_PRIVATE;
  }

  function userIsBlocked(user) {
    return user.isBlocked;
  }

  function resourceHasCommentsDisabled(user, resource) {
    return resource.isCommentsDisabled;
  }

  function resourceHasRoles() {
    for (var _len6 = arguments.length, roles = new Array(_len6), _key6 = 0; _key6 < _len6; _key6++) {
      roles[_key6] = arguments[_key6];
    }

    return function (user, resource) {
      return (0, _lodash.includes)(roles, (0, _userRole.getUserRole)(resource));
    };
  }

  function resourceOwnerHasRoles() {
    for (var _len7 = arguments.length, roles = new Array(_len7), _key7 = 0; _key7 < _len7; _key7++) {
      roles[_key7] = arguments[_key7];
    }

    return function (user, resource) {
      return (0, _lodash.includes)(roles, (0, _userRole.getUserRole)((0, _lodash.get)(resource, 'owner')));
    };
  }

  function resourceIsMembersOnly(user, resource) {
    return resolve(resource).type ? resolve(resource).type === 'membersOnly' : resolve(resource).isPrivate;
  }

  function resourceIsAccesible(user, resource) {
    if (resolve(resource).type === 'private') {
      var hasMatchingElements = function hasMatchingElements(arr1, arr2) {
        return arr1.some(function (r) {
          return arr2.includes(r);
        });
      };

      var groups = resolve(resource).groups || [];
      return hasMatchingElements(groups, user.groups || []);
    }

    return true;
  }

  function resourceIsWriteProtected(user, resource) {
    return resolve(resource).isWriteProtected;
  }

  function resourceIsBlocked(user, resource) {
    return resource.isBlocked;
  }

  function rolify(obj, role) {
    return (0, _extends2["default"])({}, obj, {
      getRoles: function getRoles() {
        return [role];
      }
    });
  }
}

function resourcify(obj, resource) {
  return obj ? (0, _extends2["default"])({}, obj, {
    getResourceId: function getResourceId() {
      return resource;
    }
  }) : resource;
}

module.exports = {
  ROLE_GUEST: ROLE_GUEST,
  ROLE_USER: ROLE_USER,
  ROLE_MODERATOR: ROLE_MODERATOR,
  ROLE_ADMIN: ROLE_ADMIN,
  ROLE_OWNER: ROLE_OWNER,
  RESOURCE_FORUM: RESOURCE_FORUM,
  RESOURCE_CATEGORY: RESOURCE_CATEGORY,
  RESOURCE_POST: RESOURCE_POST,
  RESOURCE_COMMENT: RESOURCE_COMMENT,
  RESOURCE_MEMBER: RESOURCE_MEMBER,
  PERMISSIONS_ALL: PERMISSIONS_ALL,
  resourcify: resourcify,
  permissionsChecker: permissionsChecker
};
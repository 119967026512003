var _require = require('./permissions-checker-v2'),
    PermissionsCheckerV2 = _require.PermissionsCheckerV2;

var _require2 = require('./permissions'),
    getPermissions = _require2.getPermissions;

module.exports = {
  permissionsChecker: permissionsChecker
};

function permissionsChecker() {
  var permissions = getPermissions();
  var pc = new PermissionsCheckerV2();
  var permissionsTable = {
    forum: {
      'reorder-categories': isPermitted(permissions.forum.reorderCategories),
      edit: isPermitted(permissions.forum.edit),
      provision: isPermitted(permissions.wix.installApps)
    },
    category: {
      list: or(isPermitted(permissions.category.read), isPermitted(permissions.category.readAll)),
      read: categoryIsAccessible(),
      create: isPermitted(permissions.category.create),
      "delete": isPermitted(permissions.category["delete"]),
      edit: isPermitted(permissions.category.edit),
      'create-post': and(isPermittedAndCategoryIsAccessible(permissions.category.createPost), or(not(isWriteProtectedCategory()), and(isWriteProtectedCategory(), isPermitted(permissions.category.createPostInWriteProtected)))),
      subscribe: isPermittedAndCategoryIsAccessible(permissions.category.subscribe),
      'mark-read': isPermitted(permissions.category.markRead)
    },
    post: {
      create: isPermitted(permissions.category.createPost),
      list: isPermitted(permissions.post.read),
      read: isPermittedAndCategoryIsAccessible(permissions.post.read),
      like: isPermittedAndCategoryIsAccessible(permissions.post.like),
      pin: isPermittedAndCategoryIsAccessible(permissions.post.pin),
      'toggle-comments': isPermittedAndCategoryIsAccessible(permissions.post.toggleComments),
      'add-best-answer-comment': or(and(isPermitted(permissions.post.setBestAnswer), isResourceOwner()), isPermittedAndCategoryIsAccessible(permissions.post.setBestAnswerForAll)),
      'remove-best-answer-comment': or(and(isPermitted(permissions.post.removeBestAnswer), isResourceOwner()), isPermittedAndCategoryIsAccessible(permissions.post.removeBestAnswerForAll)),
      'create-comment': and(isPermittedAndCategoryIsAccessible(permissions.post.createComment), not(isCommentsDisabledPost())),
      report: isPermittedAndCategoryIsAccessible(permissions.post.report),
      move: isPermittedAndCategoryIsAccessible(permissions.post.move),
      edit: or(and(isPermitted(permissions.post.edit), isResourceOwner()), isPermittedAndCategoryIsAccessible(permissions.post.editAll)),
      "delete": or(and(isPermitted(permissions.post["delete"]), isResourceOwner()), isPermittedAndCategoryIsAccessible(permissions.post.deleteAll)),
      subscribe: isPermittedAndCategoryIsAccessible(permissions.post.subscribe),
      'mark-read': isPermitted(permissions.post.markRead),
      'mark-seen': isPermitted(permissions.post.markSeen)
    },
    comment: {
      list: isPermitted(permissions.comment.read),
      read: isPermittedAndCategoryIsAccessible(permissions.comment.read),
      like: isPermittedAndCategoryIsAccessible(permissions.comment.like),
      vote: isPermittedAndCategoryIsAccessible(permissions.comment.vote),
      report: isPermittedAndCategoryIsAccessible(permissions.comment.report),
      "delete": or(and(isPermitted(permissions.comment["delete"]), isResourceOwner()), isPermittedAndCategoryIsAccessible(permissions.comment.deleteAll)),
      edit: or(and(isPermitted(permissions.comment.edit), isResourceOwner()), isPermittedAndCategoryIsAccessible(permissions.comment.editAll))
    },
    mobileSettings: {
      get: isPermitted(permissions.mobileSettings.get),
      edit: isPermitted(permissions.mobileSettings.edit)
    }
  };
  Object.entries(permissionsTable).forEach(function (_ref) {
    var resource = _ref[0],
        actions = _ref[1];
    Object.entries(actions).forEach(function (_ref2) {
      var action = _ref2[0],
          permission = _ref2[1];
      pc.allow(resource, action, permission);
    });
  });
  return {
    withContext: function withContext(requestor, categoryResolver) {
      return {
        getRequestor: function getRequestor() {
          return requestor;
        },
        can: function can(resource, action) {
          return pc.can({
            requestor: requestor,
            categoryResolver: categoryResolver
          }, resource, action);
        }
      };
    },
    getPermissionChecker: function getPermissionChecker() {
      return pc;
    }
  };

  function isPermittedAndCategoryIsAccessible(permission) {
    return and(isPermitted(permission), categoryIsAccessible());
  }

  function isPermitted(permission) {
    return function (c) {
      return c.isPermitted(permission);
    };
  }

  function and() {
    for (var _len = arguments.length, fns = new Array(_len), _key = 0; _key < _len; _key++) {
      fns[_key] = arguments[_key];
    }

    return function (c) {
      return c.and.apply(c, fns.map(function (fn) {
        return fn(c);
      }));
    };
  }

  function or() {
    for (var _len2 = arguments.length, fns = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      fns[_key2] = arguments[_key2];
    }

    return function (c) {
      return c.or.apply(c, fns.map(function (fn) {
        return fn(c);
      }));
    };
  }

  function not(fn) {
    return function (c) {
      return c.not(fn(c));
    };
  }

  function isWriteProtectedCategory() {
    return function (c) {
      return c.isWriteProtectedCategory();
    };
  }

  function isResourceOwner() {
    return function (c) {
      return c.isResourceOwner();
    };
  }

  function isCommentsDisabledPost() {
    return function (c) {
      return c.isCommentsDisabledPost();
    };
  }

  function categoryIsAccessible() {
    return or(isPermitted(permissions.category.readAll), and(isPermitted(permissions.category.read), function (c) {
      return c.hasAccessToCategory();
    }));
  }
}
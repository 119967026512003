import { Ordering } from '@wix/comments-ooi-client/controller';
import { isQuestion } from '@wix/communities-forum-client-commons';

const PAGINATION_CONFIG = {
  replyShowMoreLimit: 8,
  ordering: Ordering.LATEST_FIRST,
  initialPage: {
    offset: 0,
    commentLimit: 20,
    replyLimit: 3,
  },
  pagination: {
    commentLimit: 20,
    replyLimit: 3,
  },
  maxPagesBeforeDrop: {
    commentPages: 2,
    replyPages: 4,
  },
};
const PAGINATION_CONFIG_VOTES = { ...PAGINATION_CONFIG, ordering: Ordering.MOST_VOTES_FIRST };

export const getPaginationConfig = post => {
  return isQuestion(post?.postType) ? PAGINATION_CONFIG_VOTES : PAGINATION_CONFIG;
};

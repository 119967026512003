import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import Highlighter from '../highlighter';
import Link from '../link/internal-link';
import BestAnswerIconLink from '../best-answer-icon-link';
import withTranslate from '../../../common/components/with-translate/with-translate';
import styles from './post-title.scss';
import withExperiment from '../../hoc/with-experiment';
import {
  EXPERIMENT_WIX_COMMENTS,
  EXPERIMENT_MARKED_COMMENTS_CLIENT,
  EXPERIMENT_RECENT_ACTIVITY_CATS,
} from '@wix/communities-forum-client-commons';
import { getMarkedCommentLabel } from '../../services/get-marked-comment-label';
import IconTooltip from '../icon-tooltip';
import { isPostIndicatorVisible, isPostTitleDimmed } from './post-title.utils';

const renderTitle = (title, to, Link) => {
  return to ? (
    <Link to={to} className={classNames(styles.link, 'forum-text-color', 'forum-link-hover-color')}>
      {title}
    </Link>
  ) : (
    <span>{title}</span>
  );
};

class PostTitle extends Component {
  constructor(props) {
    super(props);
    this.titleRef = React.createRef();
  }

  getLineCount() {
    if (this.titleRef.current) {
      const computedStyles = window.getComputedStyle(this.titleRef.current);
      const lineHeight = parseInt(computedStyles.lineHeight, 10);
      return Math.max(this.titleRef.current.offsetHeight / lineHeight, 1);
    }
  }

  componentDidMount() {
    this.props.setLineCount && this.props.setLineCount(this.getLineCount());
  }

  render() {
    const {
      title,
      type,
      query,
      className,
      to,
      component: Component,
      t,
      id,
      post,
      category,
      clamp,
      showBestAnswer,
      isPostSeen,
      isPostNew,
      isInline = false,
      isWixCommentsEnabled,
      isMarkedCommentsEnabled,
      isRecentActivityCatsEnabled,
    } = this.props;
    const isTitlePotentiallyMoreThanOneLine = title.length > 38;
    const showPostIndicator = isPostIndicatorVisible({
      isRecentActivityCatsEnabled,
      type,
      isPostSeen,
      isPostNew,
      lastActivityBeforeMemberCreated: post?.lastActivityBeforeMemberCreated,
    });
    const containerClassName = classNames(
      styles.container,
      styles[type],
      'post-title',
      className,
      isWixCommentsEnabled ? styles.newWixComments : null,
      isPostTitleDimmed({
        isRecentActivityCatsEnabled,
        type,
        isPostSeen,
      })
        ? styles.isSeen
        : null,
    );

    const clampStyle = clamp > 0 ? { WebkitLineClamp: clamp, MozLineClamp: clamp } : {};
    const isClamped =
      isInline || this.titleRef.current?.scrollHeight > this.titleRef.current?.clientHeight;
    const hasMarkedComments = !!post?.markedComments;
    const markedCommentsLabel = getMarkedCommentLabel(category?.commentsMark, t);

    return (
      <Component id={id} className={containerClassName} data-hook="post-title">
        <div className={styles.title}>
          {isMarkedCommentsEnabled && showBestAnswer && hasMarkedComments && markedCommentsLabel ? (
            <div
              className={classNames(
                styles.bestAnswerIcon,
                isTitlePotentiallyMoreThanOneLine && styles.extraSpacing,
              )}
            >
              <BestAnswerIconLink
                t={t}
                // @TODO update commentId to use value from markedComments, when back-end is ready
                commentId={post.bestAnswerCommentId}
                post={post}
                linkComponent={Link}
                toolTipClassName={styles.tooltip}
                iconClassName={styles.icon}
                toolTipContent={t('post-list-item.marked-comment-tooltip', {
                  label: markedCommentsLabel,
                })}
              />
            </div>
          ) : (
            // @TODO should be removed, when migration to new comments and marked comments is done
            showBestAnswer &&
            Boolean(post?.bestAnswerCommentId) && (
              <div
                className={classNames(
                  styles.bestAnswerIcon,
                  isTitlePotentiallyMoreThanOneLine && styles.extraSpacing,
                )}
              >
                <BestAnswerIconLink
                  t={t}
                  commentId={post.bestAnswerCommentId}
                  post={post}
                  linkComponent={Link}
                  toolTipClassName={styles.tooltip}
                  iconClassName={styles.icon}
                />
              </div>
            )
          )}
          <div
            ref={this.titleRef}
            className={classNames(
              styles.linkContainer,
              clamp > 0 ? styles.lineClamp : '',
              showPostIndicator && isClamped ? styles.linkContainerClamped : null,
            )}
            style={clampStyle}
          >
            {renderTitle(query ? <Highlighter text={title} query={query} /> : title, to, Link)}
            {showPostIndicator && (
              <div
                data-hook="new-post-activity-indicator"
                className={classNames(
                  styles.indicatorWrapper,
                  isClamped ? styles.indicatorFloating : null,
                )}
              >
                &nbsp;
                <IconTooltip
                  text={t('post-title.new-activity')}
                  className={styles.indicatorTooltip}
                >
                  <div className={classNames(styles.indicator, 'color-8-background')}></div>
                </IconTooltip>
              </div>
            )}
          </div>
        </div>
      </Component>
    );
  }
}

PostTitle.SMALL = 'small'; // TODO remove after new card done
PostTitle.MEDIUM = 'medium'; // TODO remove after new card done
PostTitle.SEARCH = 'search';
PostTitle.FOOTER_POST = 'footerPost';
PostTitle.POST_PAGE = 'postPage';
PostTitle.RECENT_POSTS_WIDGET = 'recentPostsWidget';

PostTitle.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  query: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  to: PropTypes.string,
  component: PropTypes.any,
  t: PropTypes.func,
  clamp: PropTypes.number,
  showBestAnswer: PropTypes.bool,
  isPostSeen: PropTypes.bool,
  isPostNew: PropTypes.bool,
  isInline: PropTypes.bool,
  setLineCount: PropTypes.func,
};

PostTitle.defaultProps = {
  component: 'h2',
  clamp: 0,
  showBestAnswer: true,
};

export default flowRight(
  withTranslate,
  withExperiment({
    isWixCommentsEnabled: EXPERIMENT_WIX_COMMENTS,
    isMarkedCommentsEnabled: EXPERIMENT_MARKED_COMMENTS_CLIENT,
    isRecentActivityCatsEnabled: EXPERIMENT_RECENT_ACTIVITY_CATS,
  }),
)(PostTitle);

import { createAction } from '@reduxjs/toolkit';

export const PICK_FORUM_SUBJECT = 'userEvents/PICK_FORUM_SUBJECT';
export const CHOOSE_FORUM_STRUCTURE = 'userEvents/CHOOSE_FORUM_STRUCTURE';
export const CUSTOMIZE_RCE = 'userEvents/CUSTOMIZE_RCE';
export const SHOW_FOOTER_POSTS = 'userEvents/SHOW_FOOTER_POSTS';
export const SHOW_HEADER = 'userEvents/SHOW_HEADER';
export const RESIZE_HEADER = 'userEvents/RESIZE_HEADER';
export const CUSTOMIZE_FEED = 'userEvents/CUSTOMIZE_FEED';
export const LOGIN_FROM_FORUM = 'userEvents/LOGIN_FROM_FORUM';
export const SELECT_FONT = 'userEvents/SELECT_FONT';
export const SELECT_FONT_SIZE = 'userEvents/SELECT_FONT_SIZE';
export const DELETE_CATEGORY = 'userEvents/DELETE_CATEGORY';
export const EDIT_CATEGORY = 'userEvents/EDIT_CATEGORY';
export const DISCARD_CHANGES_CATEGORY = 'userEvents/DISCARD_CHANGES_CATEGORY';
export const CREATE_CATEGORY = 'userEvents/CREATE_CATEGORY';
export const SHOW_SUBCATEGORIES = 'userEvents/SHOW_SUBCATEGORIES';
export const MAKE_CATEGORY_CHILD_OR_PARENT = 'userEvents/MAKE_CATEGORY_CHILD_OR_PARENT';
export const SET_POST_LIMIT = 'userEvents/SET_POST_LIMIT';
export const SHOW_EMPTY_STATE = 'userEvents/SHOW_EMPTY_STATE';
export const MOVE_POST = 'userEvents/MOVE_POST';
export const REPLY_OR_COMMENT_INTENT = 'userEvents/REPLY_OR_COMMENT_INTENT';
export const CLICK_PUBLISH = 'userEvents/CLICK_PUBLISH';
export const CLICK_LIKE = 'userEvents/CLICK_LIKE';
export const CLICK_PROFILE_LINK = 'userEvents/CLICK_PROFILE_LINK';
export const CLICK_CREATE_POST = 'userEvents/CLICK_CREATE_POST';
export const CLICK_CREATE_POST_BUTTON = 'userEvents/CLICK_CREATE_POST_BUTTON';
export const POST_CAPTCHA_SHOWN = 'userEvents/POST_CAPTCHA_SHOWN';
export const POST_CAPTCHA_RESOLVED = 'userEvents/POST_CAPTCHA_RESOLVED';
export const COMMENT_CAPTCHA_SHOWN = 'userEvents/COMMENT_CAPTCHA_SHOWN';
export const COMMENT_CAPTCHA_RESOLVED = 'userEvents/COMMENT_CAPTCHA_RESOLVED';
export const GUIDELINES_SHOWN = 'userEvents/GUIDELINES_SHOWN';
export const POSTING_DISABLED_SHOWN = 'userEvents/POSTING_DISABLED_SHOWN';
export const POSTING_DISABLED_CLOSE = 'userEvents/POSTING_DISABLED_CLOSE';
export const SEARCH_FOCUSED = 'userEvents/SEARCH_FOCUSED';
export const SEARCH_CLEARED = 'userEvents/SEARCH_CLEARED';
export const SEARCH_SUGGESTIONS_LOADED = 'userEvents/SEARCH_SUGGESTIONS_LOADED';
export const SEARCH_SUBMITTED = 'userEvents/SEARCH_SUBMITTED';

export const userEventsPickForumSubject = createAction(PICK_FORUM_SUBJECT);
export const userEventsChooseForumStructure = createAction(CHOOSE_FORUM_STRUCTURE);
export const userEventsCustomizeRCE = createAction(CUSTOMIZE_RCE);
export const userEventsShowFooterPosts = createAction(SHOW_FOOTER_POSTS);
export const userEventsShowHeader = createAction(SHOW_HEADER);
export const userEventsResizeHeader = createAction(RESIZE_HEADER);
export const userEventsCustomizeFeed = createAction(CUSTOMIZE_FEED);
export const userEventsLoginFromForum = createAction(LOGIN_FROM_FORUM);
export const userEventsSelectFont = createAction(SELECT_FONT);
export const userEventsSelectFontSize = createAction(SELECT_FONT_SIZE);
export const userEventsDeleteCategory = createAction(DELETE_CATEGORY);
export const userEventsEditCategory = createAction(EDIT_CATEGORY);
export const userEventsCreateCategory = createAction(CREATE_CATEGORY);
export const userEventsShowSubcategories = createAction(SHOW_SUBCATEGORIES);
export const userEventsMakeCategoryChildOrParent = createAction(MAKE_CATEGORY_CHILD_OR_PARENT);
export const userEventsSetPostLimit = createAction(SET_POST_LIMIT);
export const userEventsDiscardChangesCategory = createAction(DISCARD_CHANGES_CATEGORY);
export const userEventsShowEmptyState = createAction<ShowEmptyState>(SHOW_EMPTY_STATE);
export const userEventsMovePost = createAction(MOVE_POST);
export const userEventsReplyOrCommentIntent = createAction<ReplyOrCommentIntent>(
  REPLY_OR_COMMENT_INTENT,
);
export const userEventsClickPublish = createAction<ClickPublish>(CLICK_PUBLISH);
export const userEventsClickLike = createAction<ClickLike>(CLICK_LIKE);
export const userEventsClickProfileLink = createAction<ClickProfileLink>(CLICK_PROFILE_LINK);
export const userEventsClickCreatePost = createAction<string>(CLICK_CREATE_POST);
export const userEventsClickCreatePostButton = createAction(CLICK_CREATE_POST_BUTTON);
export const userEventsPostCaptchaShown = createAction(POST_CAPTCHA_SHOWN);
export const userEventsPostCaptchaResolved = createAction<boolean>(POST_CAPTCHA_RESOLVED);
export const userEventsCommentCaptchaShown = createAction(COMMENT_CAPTCHA_SHOWN);
export const userEventsCommentCaptchaResolved = createAction<boolean>(COMMENT_CAPTCHA_RESOLVED);
export const userEventsGuidelinesShown = createAction<GuidelinesShown>(GUIDELINES_SHOWN);
export const userEventsPostingDisabledShown = createAction<boolean>(POSTING_DISABLED_SHOWN);
export const userEventsPostingDisabledClose = createAction(POSTING_DISABLED_CLOSE);
export const userEventsSearchFocused = createAction(SEARCH_FOCUSED);
export const userEventsSearchCleared = createAction(SEARCH_CLEARED);
export const userEventsSearchSuggestionsLoaded = createAction<string>(SEARCH_SUGGESTIONS_LOADED);
export const userEventsSearchSubmitted = createAction<SearchSubmitted>(SEARCH_SUBMITTED);

interface ClickProfileLink {
  isOwnProfile: boolean;
}

interface ShowEmptyState {
  type: string;
  categoryId: string;
}

interface ClickLike {
  _id: string;
  type: string;
  isLiked: boolean;
}

interface ClickPublish {
  postId?: string;
  type: string;
  symbolCount: number;
  isEditing: boolean;
}

interface ReplyOrCommentIntent {
  type: string;
  origin?: string;
}

interface GuidelinesShown {
  type: string;
  origin: string;
  categoryId: string;
}

interface SearchSubmitted {
  query: string;
  location: string;
}

import { get } from 'lodash';

export const getForumData = state => state.forumData;
export const getCommunityId = state => getForumData(state).communityId;
export const getInstanceId = state => getForumData(state)._id;
export const getForumTitle = state => getForumData(state).label;
export const getIsForumHot = state => getForumData(state).isHot;
export const getPostLimiter = state => getForumData(state).newPostLimiter;
export const getAttachmentSizeLimit = state => getForumData(state).attachmentSizeLimit;
export const getCaptchaSettings = state => getForumData(state).captchaSettings || {};
export const getSpamWords = state => getForumData(state).stopWords || [];
export const getFilteredWords = state => getForumData(state).wordsFilter || [];

export const getIsRegistrationWithApproval = state =>
  getForumData(state).registrationManuallyApproved;

export const getIsEmailConfirmationOn = state => getForumData(state).isEmailConfirmationOn;

export const getRegistrationIsOpen = state => getForumData(state).registrationOpened;

export const getIsVideoDurationLimitReached = state =>
  get(getForumData(state), 'mediaQuota.videoDurationExceeded', false);

export const getIsForumSpammed = state => getForumData(state).isForumSpammed;

export const getIsPostingDisabled = state => getForumData(state).isPostingDisabled;

export const getForumCreatePostCta = state => getForumData(state).createPostCtaLabel;

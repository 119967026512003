var PERMISSIONS = {
  wix: {
    installApps: 'APP_MARKET.INSTALL_APP'
  },
  forum: {
    reorderCategories: 'WIX_FORUM.FORUM_REORDER_CATEGORIES',
    edit: 'WIX_FORUM.FORUM_EDIT',
    provision: 'WIX_FORUM.FORUM_PROVISION'
  },
  category: {
    read: 'WIX_FORUM.CATEGORY_READ',
    readAll: 'WIX_FORUM.CATEGORY_READ_ALL',
    create: 'WIX_FORUM.CATEGORY_CREATE',
    "delete": 'WIX_FORUM.CATEGORY_DELETE',
    edit: 'WIX_FORUM.CATEGORY_EDIT',
    createPost: 'WIX_FORUM.CATEGORY_CREATE_POST',
    createPostInWriteProtected: 'WIX_FORUM.CATEGORY_CREATE_POST_WRITE_PROTECTED',
    subscribe: 'WIX_FORUM.CATEGORY_SUBSCRIBE',
    markRead: 'WIX_FORUM.CATEGORY_MARK_READ'
  },
  post: {
    read: 'WIX_FORUM.POST_READ',
    like: 'WIX_FORUM.POST_LIKE',
    pin: 'WIX_FORUM.POST_PIN',
    toggleComments: 'WIX_FORUM.POST_TOGGLE_COMMENTS',
    setBestAnswer: 'WIX_FORUM.POST_ADD_BEST_ANSWER_COMMENT',
    setBestAnswerForAll: 'WIX_FORUM.POST_ADD_BEST_ANSWER_COMMENT_ALL',
    removeBestAnswer: 'WIX_FORUM.POST_REMOVE_BEST_ANSWER_COMMENT',
    removeBestAnswerForAll: 'WIX_FORUM.POST_REMOVE_BEST_ANSWER_COMMENT_ALL',
    createComment: 'WIX_FORUM.POST_CREATE_COMMENT',
    report: 'WIX_FORUM.POST_REPORT',
    move: 'WIX_FORUM.POST_MOVE',
    edit: 'WIX_FORUM.POST_EDIT',
    editAll: 'WIX_FORUM.POST_EDIT_ALL',
    "delete": 'WIX_FORUM.POST_DELETE',
    deleteAll: 'WIX_FORUM.POST_DELETE_ALL',
    subscribe: 'WIX_FORUM.POST_SUBSCRIBE',
    markRead: 'WIX_FORUM.POST_MARK_READ',
    markSeen: 'WIX_FORUM.POST_MARK_SEEN'
  },
  comment: {
    read: 'WIX_FORUM.COMMENT_READ',
    like: 'WIX_FORUM.COMMENT_LIKE',
    vote: 'WIX_FORUM.COMMENT_VOTE',
    report: 'WIX_FORUM.COMMENT_REPORT',
    "delete": 'WIX_FORUM.COMMENT_DELETE',
    deleteAll: 'WIX_FORUM.COMMENT_DELETE_ALL',
    edit: 'WIX_FORUM.COMMENT_EDIT',
    editAll: 'WIX_FORUM.COMMENT_EDIT_ALL'
  },
  mobileSettings: {
    get: 'WIX_FORUM.MOBILE_SETTINGS_GET',
    edit: 'WIX_FORUM.MOBILE_SETTINGS_EDIT'
  }
};

function getPermissions() {
  return PERMISSIONS;
}

function getGuestPermissions() {
  return [PERMISSIONS.category.read, PERMISSIONS.category.markRead, PERMISSIONS.post.read, PERMISSIONS.post.markRead, PERMISSIONS.post.markSeen, PERMISSIONS.comment.read, PERMISSIONS.mobileSettings.get];
}

function getPrivateUserPermissions() {
  return [PERMISSIONS.category.read, PERMISSIONS.post.read, PERMISSIONS.post.edit, PERMISSIONS.post["delete"], PERMISSIONS.comment.read, PERMISSIONS.comment.edit, PERMISSIONS.comment["delete"], PERMISSIONS.mobileSettings.get];
}

module.exports = {
  getPermissions: getPermissions,
  getPrivateUserPermissions: getPrivateUserPermissions,
  getGuestPermissions: getGuestPermissions
};